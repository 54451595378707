/**
 * 搜索列表
 */
import Vue from 'vue'
import { Page,Input,DatePicker,locale,Table,Button,Icon,Dropdown,DropdownMenu,DropdownItem } from 'iview';
import lang from 'iview/dist/locale/en-US';
locale(lang)
Vue.component('Page', Page);
Vue.component('Input', Input);
Vue.component('DatePicker', DatePicker);
Vue.component('Table', Table);
Vue.component('Button', Button);
Vue.component('Icon', Icon);
Vue.component('Dropdown', Dropdown);
Vue.component('DropdownMenu', DropdownMenu);
Vue.component('DropdownItem', DropdownItem);
import { formatUnit } from '@/utils/tools'
import { keywordsList,
    brandsColumns,
    categoryColumns,
    getBrandsListService,
    getCategoryListService
} from '@/service/keywords-service'
import Header from '@/components/Header/header.vue'
import { mapMutations } from "vuex";
import { classification } from "@/service/spu-group-service";
const KeywordsList = {
    name:'KeywordsList',
    components:{
        Header
    },
    data() {
        return {
            formatUnit:formatUnit,
            monthValue:'', //年月值
            weekValue:'', //周值
            keywordsList:keywordsList,//tab 数据集
            brandsColumns:brandsColumns,//thead 数据集
            categoryColumns:categoryColumns,//thead 数据集
            classification:classification, //分类
            keywordsLoading:false,
            keywordsStatus:false,
            keywordsArray:[], //关键词数据集
            selectAllCancel:[], //全选时的临时存储
            selectAllContentCancel:[], //全选时的临时存储
            btnStatus:false,
            keywordsParams:{  //选中参数
                cid1:1,
                keywordsValue:1,
                checkContentInput:[],
            },
        }
    },
    created() {
        this.SET_TYPE_ID('1')
        this.SET_LAUNCH_TAB('1')
        this.onBrandsList().then()
    },
    methods: {
        ...mapMutations('launch',['SET_LAUNCH_ID','SET_LAUNCH_NAME','SET_LAUNCH_TITLE','SET_TYPE_ID','SET_LAUNCH_TAB']),
        /**
         * 点击全选时触发
         * selection: 以选项数据
         * @param selection
         */
        onSelectContentAll(selection) {
            this.selectAllContentCancel = selection
            for(let key in selection){
                let flag = true
                for(let index in this.searchParams.checkContentInput){
                    if(selection[key].id === this.searchParams.checkContentInput[index]){
                        flag = false
                    }
                }
                if(flag){
                    this.searchParams.checkContentInput.push(selection[key].id)
                }
            }
        },
        /**
         * 点击取消全选时触发
         * selection: 以选项数据
         * @param selection
         */
        onSelectContentAllCancel() {
            let selection = this.selectAllContentCancel
            for(let key in selection){
                for(let index in this.searchParams.checkContentInput){
                    if(selection[key].id === this.searchParams.checkContentInput[index]){
                        this.searchParams.checkContentInput.splice(index,1);
                    }
                }
            }
        },
        /**
         * 选中某以一项时触发
         * selection: 以选项数据
         * row: 刚选择的数据
         * some() 方法用于检测数组中的元素是否满足指定条件
         * 注意： some() 不会对空数组进行检测。
         * 注意： some() 不会改变原始数组。
         * @param selection，row
         */
        onSelectContent(selection,row) {
            let mediaList = this.searchParams.checkContentInput.some((e)=>{
                return e.id === row.id
            })
            if(!mediaList){
                this.searchParams.checkContentInput.push(row.id)
            }
        },
        /**
         * 取消选中某一项时触发
         * selection: 以选项数据
         * row: 取消选择的项数据
         * 遍历当前选中数组 如有相同则在当前数组的指定位置进行删除
         * @param selection，row
         */
        onSelectContentCancel(selection,row) {
            for(let key in this.searchParams.checkContentInput){
                if(this.searchParams.checkContentInput[key] === row.id){
                    this.searchParams.checkContentInput.splice(key,1);
                }
            }
        },
        /**
         * 选择分类id
         * @param id
         */
        onTypeSelect(id){
            this.keywordsParams.cid1 = id
            this.SET_TYPE_ID(id)
            this.onCategoryList().then()
        },
        /**
         * 返回至列表页
         */
        onBack() {
            this.$router.back()
        },
        /**
         * 添加数据
         */
        onAddContentList(){
            if(this.searchParams.searchValue === 1){
                this.SET_LAUNCH_NAME('Homepage')
            }
            if(this.searchParams.searchValue === 2){
                this.SET_LAUNCH_NAME('Recommend')
            }
            if(this.searchParams.searchValue === 3){
                this.SET_LAUNCH_NAME('Keywords')
            }
            if(this.searchParams.searchValue === 4){
                this.SET_LAUNCH_NAME('Brands')
            }
            if(this.searchParams.searchValue === 5){
                this.SET_LAUNCH_NAME('Featured')
            }
            this.SET_LAUNCH_ID('')
            this.$router.push('/search/edit')
        },
        /**
         * 跳转编辑
         * @param row
         */
        onEditContent(row) {
            this.SET_LAUNCH_ID(row.id)
            this.SET_LAUNCH_NAME(row.name)
            this.$router.push('/keywords/edit')
        },
        /**
         * 选择tab
         * @param value
         */
        onSelectTab(value) {
            this.keywordsParams.keywordsValue = value
            this.SET_LAUNCH_TAB(value)
            this.keywordsParams.checkContentInput = []
            if(value === 1){
                this.onBrandsList().then()
            }
            if(value === 2){
                this.onCategoryList().then()
            }
        },
        /**
         * 获取Brands数据
         * @returns {Promise<void>}
         */
        async onBrandsList() {
            try {
                this.keywordsLoading = true
                let params = {}
                const {code,data} = await getBrandsListService(params)
                if(code === 1){
                    this.keywordsArray = data
                }
                this.keywordsLoading = false
            }catch (error){
                this.keywordsLoading = false
                console.log(error)
            }
        },
        /**
         * 获取Category数据
         * @returns {Promise<void>}
         */
        async onCategoryList() {
            try {
                this.keywordsLoading = true
                let params = {
                    cid1:this.keywordsParams.cid1
                }
                const {code,data} = await getCategoryListService(params)
                if(code === 1){
                    this.keywordsArray = data
                }
                this.keywordsLoading = false
            }catch (error){
                this.keywordsLoading = false
                console.log(error)
            }
        },
    },
}
export default KeywordsList
