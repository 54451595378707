<template>
    <div class="layout-body">
        <!--s: Header-->
        <Header/>
        <!--e: Header-->

        <!--s: Type-->
        <div class="product-type">
            <div class="type-back"><a href="javascript:" @click="onBack">Back</a></div>
        </div>
        <!--e: Type-->

        <h1 class="list-title">Keywords</h1>

        <!--s: Launch -->
        <div class="keywords-body">

            <!--s: table-->
            <div class="launch-table">
                <div class="launch-type" v-if="keywordsParams.keywordsValue === 2">
                    <a href="javascript:" :class="keywordsParams.cid1 === item.id?'active':''" :key="key" v-for="(item,key) in classification" @click="onTypeSelect(item.id)">{{ item.name }}</a>
                </div>
                <div class="keywords-top" :style="keywordsParams.keywordsValue === 1?'margin-top: 20px;':''">
                    <div class="launch-tab">
                        <a href="javascript:" :class="keywordsParams.keywordsValue === keywords.value?'active':''" :key="key" v-for="(keywords,key) in keywordsList" @click="onSelectTab(keywords.value)">{{ keywords.label }}</a>
                    </div>
<!--                    <a href="javascript:" class="launch-tab-add" @click="onAddContentList">-->
<!--                        <Icon custom="launch-add"></Icon>-->
<!--                    </a>-->
                </div>
                <div class="launch-content">
                    <Table :loading="keywordsLoading"  :columns="keywordsParams.keywordsValue === 1?brandsColumns:categoryColumns" :data="keywordsArray" @on-select-all="onSelectContentAll" @on-select-all-cancel="onSelectContentAllCancel" @on-select="onSelectContent" @on-select-cancel="onSelectContentCancel">
                        <template slot="btn" slot-scope="{ row }">
                            <a href="javascript:" class="btn" @click="onEditContent(row)">Edit</a>
                        </template>
                    </Table>
<!--                    <div class="launch-btn" v-if="keywordsParams.keywordsValue === 2">-->
<!--                        <div class="box">-->
<!--                            <div class="pint" v-if="keywordsStatus">-->
<!--                                <span class="title">Sure delete?</span>-->
<!--                                <Button style="width:71px" :loading="btnStatus" @click="onContentDeleteConfirm">Yes</Button>-->
<!--                                <Button @click="keywordsStatus = false">Cancel</Button>-->
<!--                            </div>-->
<!--                            <Button @click="onContentDelete">Delete</Button>-->
<!--                        </div>-->
<!--                    </div>-->
                  </div>
            </div>
            <!--e: table-->

        </div>
        <!--e: Launch -->

    </div>
</template>
<script>
import LaunchList from './list'
export default LaunchList
</script>
<style lang="less">
@import "list";
</style>
